import React, { useState, useContext, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import InputField from "./CustomInputField"

const PageContentFindRates = () => {
  // const [query, setQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const data = useStaticQuery(graphql`
    query {
      wp {
        products(
          first: 9999
          where: { orderby: { field: TITLE, order: DESC } }
        ) {
          nodes {
            title
            databaseId
            slug
            featuredImage {
              node {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 50, maxHeight: 50) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const allProducts = data.wp.products.nodes

  const petersProfessionalProducts = allProducts.filter( p => {
    return p.title.includes('Peters Professional, ')
  })

  const petersProfessionalPeatLiteProducts = allProducts.filter( p => {
    return p.title.includes('Peters Professional Peat-Lite')
  })

  const petersExcelProducts = allProducts.filter( p => {
    return p.title.includes('Peters Excel, ')
  })

  const petersExcelpHLowProducts = allProducts.filter( p => {
    return p.title.includes('Peters Excel pHLow')
  })

  return (
    <div className="container mt-4">
      
      <h2 class="text-icl font-weight-normal display-4">Peters Professional</h2>
      <ul className="list-unstyled list-group list-group-flush mb-4 pb-4 border-light border-bottom">
        {petersProfessionalProducts.map((option, i) => (
          <li className="list-group-item" key={`filtered-product-${option.databaseId}`}>
            <div className="row align-items-center">
              <div style={{ width: 50, height: 50, overflow: 'hidden' }} className="border rounded-circle p-1 col-auto">
                <Img
                  fluid={option.featuredImage.node.imageFile.childImageSharp.fluid}
                  loading="eager"
                />
              </div>
              <h3 className="h5 px-3 col position-static">
                {option.title.replace('Peters Professional, ', '')}
              </h3>
              <div>
                <Link
                  to={`/products/${option.slug}`}
                  className="btn btn-icl btn-skew stretched-link"
                  state={{ showRates: true }}
                ><span>View Rates</span></Link>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <h2 class="text-icl font-weight-normal display-4">Peters Professional Peat-Lite</h2>
      <ul className="list-unstyled list-group list-group-flush mb-4 pb-4 border-light border-bottom">
        {petersProfessionalPeatLiteProducts.map((option, i) => (
          <li className="list-group-item" key={`filtered-product-${option.databaseId}`}>
            <div className="row align-items-center">
              <div style={{ width: 50, height: 50, overflow: 'hidden' }} className="border rounded-circle p-1 col-auto">
                <Img
                  fluid={option.featuredImage.node.imageFile.childImageSharp.fluid}
                  loading="eager"
                />
              </div>
              <h3 className="h5 px-3 col position-static">
                {option.title.replace('Peters Professional Peat-Lite, ', '')}
              </h3>
              <div>
                <Link
                  to={`/products/${option.slug}`}
                  className="btn btn-icl btn-skew stretched-link"
                  state={{ showRates: true }}
                ><span>View Rates</span></Link>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <h2 class="text-icl font-weight-normal display-4">Peters Excel</h2>
      <ul className="list-unstyled list-group list-group-flush mb-4 pb-4 border-light border-bottom">
        {petersExcelProducts.map((option, i) => (
          <li className="list-group-item" key={`filtered-product-${option.databaseId}`}>
            <div className="row align-items-center">
              <div style={{ width: 50, height: 50, overflow: 'hidden' }} className="border rounded-circle p-1 col-auto">
                <Img
                  fluid={option.featuredImage.node.imageFile.childImageSharp.fluid}
                  loading="eager"
                />
              </div>
              <h3 className="h5 px-3 col position-static">
                {option.title.replace('Peters Excel, ', '')}
              </h3>
              <div>
                <Link
                  to={`/products/${option.slug}`}
                  className="btn btn-icl btn-skew stretched-link"
                  state={{ showRates: true }}
                ><span>View Rates</span></Link>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <h2 class="text-icl font-weight-normal display-4">Peters Excel pHLow</h2>
      <ul className="list-unstyled list-group list-group-flush mb-4">
        {petersExcelpHLowProducts.map((option, i) => (
          <li className="list-group-item" key={`filtered-product-${option.databaseId}`}>
            <div className="row align-items-center">
              <div style={{ width: 50, height: 50, overflow: 'hidden' }} className="border rounded-circle p-1 col-auto">
                <Img
                  fluid={option.featuredImage.node.imageFile.childImageSharp.fluid}
                  loading="eager"
                />
              </div>
              <h3 className="h5 px-3 col position-static">
                {option.title.replace('Peters Excel pHLow, ', '')}
              </h3>
              <div>
                <Link
                  to={`/products/${option.slug}`}
                  className="btn btn-icl btn-skew stretched-link"
                  state={{ showRates: true }}
                ><span>View Rates</span></Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PageContentFindRates
