import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { useScroll } from "../hooks/useScroll"

const PageHeader = props => {
  const data = useStaticQuery(graphql`
    query {
      default: file(relativePath: { eq: "default.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 650) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [state, setState] = useState({
    position: 50,
  })

  const PARALLAX_SPEED = -10

  useEffect(() => {
    const handleScroll = () => {
      setState({
        ...state,
        position: 50 + window.scrollY / PARALLAX_SPEED,
      })
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // clean up event listener when component unmounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [state.position])

  // let headerParallax = `center ${ (50 + (scrollY / -4)) }%`;

  return (
    <>

        <header className="page-header page-header__full">
          <figure className="mb-3 mb-md-5 position-relative">
            {props.img ? (
              <Img
                fluid={props.img.imageFile.childImageSharp.fluid}
                imgStyle={{ objectPosition: `center ${state.position}%` }}
                loading="eager"
              />
            ) : (
              <Img
                fluid={data.default.childImageSharp.fluid}
                imgStyle={{ objectPosition: `center ${state.position}%` }}
                loading="eager"
              />
            )}
            <div id="sand-crawler">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.91 95.67" width="74.91" height="95.67" fill="#fff"><path d="M1020.83 180.26a32.8 32.8 0 0 1-28.16-16.13c-3-4.84-16.25-27.93-38.09-66.59v82.72" transform="translate(-954.58 -84.58)" /><path d="M975.93 87a5.1 5.1 0 0 0-4.39-2.41h-4.41c-1.76 0-.72 2-.72 2l53.68 93.7h9.4c-26.65-46.57-52.71-92.01-53.56-93.29Z" transform="translate(-954.58 -84.58)"/></svg>
            </div>
          </figure>
          <div className="page-title">
            <div className="container">
              <h1 dangerouslySetInnerHTML={{ __html: props.title }} className="text-icl font-weight-normal display-4"/>
            </div>
          </div>
        </header>
    </>
  )
}

export default PageHeader
