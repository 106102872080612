import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import InputField from "./CustomInputField"
import SelectField from "./CustomSelectField"
import states from "../helpers/states"
import castates from "../helpers/castates"

const PageContentFindDealer = props => {  
  const data = useStaticQuery(graphql`
    query {
      wp {
        dealers(
          first: 9999
          where: { orderby: { field: TITLE, order: ASC } }
        ) {
          nodes {
            databaseId
            title
            dealerInformation {
              phoneNumber
              address
              address2
              city
              state
              zipCode
              email
              website
              latitude
              longitude
              type
            }
          }
        }
      }
    }
  `)

  const { dealers } = data.wp
  
  const [filteredDealers, setFilteredDealers] = useState(dealers.nodes)
  const [state, setState] = useState("")
  const [zip, setZip] = useState("")
  const filterDealers = (stateValue,zipValue) => {
    let filteredDealers = dealers.nodes
	if (stateValue !== "" || zipValue != "") {
		if(stateValue !== ""){
	      filteredDealers = dealers.nodes.filter(dealer => dealer.dealerInformation.state === stateValue)
	    }
	    if(zipValue !== ""){
	      filteredDealers = dealers.nodes.filter(dealer => dealer.dealerInformation.zipCode.includes(zipValue))
	    }
	    if(stateValue !== "" && zipValue !== ""){
	      filteredDealers = dealers.nodes.filter(dealer => dealer.dealerInformation.state === stateValue && dealer.dealerInformation.zipCode.includes(zipValue))
	    }
	}
	setState(stateValue)
	setZip(zipValue)
	setFilteredDealers(filteredDealers)
  }
  
  return (
    <div className="container">
      <form>
        <SelectField name="findADealerState" label="State/Province" onSelectChange={e => filterDealers(e.target.value, '')} value={state}>
          <option value="">Choose a State/Province</option>
          <optgroup label="UNITED STATES">
            {Object.keys(states).map((state, i) => (
              <option value={state} key={i}>{states[state]}</option>
            ))}
          </optgroup>
          <optgroup label="CANADA">
          	{Object.keys(castates).map((castate, i) => (
              <option value={castate} key={i}>{castates[castate]}</option>
            ))}
          </optgroup>
        </SelectField>
        {/*
        <InputField name="findADealerZip" label="Zip Code" type="text" onInputChange={e => filterDealers(document.getElementById('findADealerState').value, e.target.value)} value={zip} />
		*/}
      </form>
      <ul className="list-unstyled">
        { 
	        filteredDealers.length > 0 ?
		    		filteredDealers.map(dealer => {
		          const {
		            phoneNumber,
		            address,
		            address2,
		            city,
		            state,
		            zipCode,
		            email,
		            website,
		            latitude,
		            longitude,
		            type
		          } = dealer.dealerInformation
	          
		          const cleanPhoneNumber = phoneNumber.replace(/–/g,'').replace(/1-800/g,'800').replace(/\(/g,'').replace(/\)/g,'').replace(/\./g,'').replace(/-/g,'').replace(/ /g,'')
		          const formattedPhoneNumber = `(${cleanPhoneNumber.substring(0,3)}) ${cleanPhoneNumber.substring(3, 6)}-${cleanPhoneNumber.substring(6)}`
						  const cleanWebsite = website.replace(/http:\/\//g,'').replace(/https:\/\//g,'')
						  const formattedWebsite = `http://${cleanWebsite}`
		          return (
		            <li key={dealer.databaseId} className={`border-top border-light mt-3 pt-3 ${zipCode} ${state}`}>
		              <div className="row">
		                <div className="col">
		                  <h2>{dealer.title}</h2>
		                  <p>
		                    {address} {address2}
		                    <br />
		                    {city}, {state} {zipCode}
		                  </p>
		                </div>
						{ <div className="col-auto pl-0">
		                  <a
		                    href={formattedWebsite}
		                    className="phone-icon"
		                    title={`Visit ${formattedWebsite}`}
		                    target='_blank'
		                  >
		                    <span className="sr-only">{formattedWebsite}</span>
							<svg height='35' width='35' viewBox="0 0 35 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M21.5,27.8c-0.2,0.1-0.5,0.2-0.7,0.3c0.2-0.3,0.4-0.5,0.5-0.8l-1-2.4c-0.9,2-2.1,3.4-3.3,3.9V24c1,0,2.1,0.1,3.1,0.3  l-0.9-2.1c-0.7-0.1-1.4-0.1-2.2-0.2v-5h5c0,0.7-0.1,1.4-0.2,2l1.9,0.8c0.1-0.9,0.2-1.9,0.2-2.8h5c-0.1,1.6-0.5,3.1-1.2,4.5l1.8,0.8  c0.9-2,1.4-4.1,1.4-6.3c0-4.3-1.9-8.4-5.1-11.2C23.2,2.3,19.7,1,16,1c-3.7,0-7.2,1.3-9.9,3.7C2.9,7.6,1,11.7,1,16s1.9,8.4,5.1,11.2  C8.8,29.7,12.3,31,16,31c2.2,0,4.3-0.5,6.3-1.4L21.5,27.8z M26.2,7.9c1.6,2,2.6,4.5,2.8,7.1h-5c-0.1-2.1-0.4-4.1-0.9-5.9  C24.2,8.7,25.3,8.4,26.2,7.9z M24.6,6.3c0,0,0.1,0.1,0.1,0.1c-0.7,0.3-1.4,0.6-2.2,0.8c-0.5-1.2-1.1-2.3-1.7-3.2  C22.2,4.5,23.5,5.3,24.6,6.3z M17,3.2c1.3,0.5,2.6,2.1,3.5,4.4C19.4,7.8,18.2,7.9,17,8V3.2z M17,10c1.4,0,2.8-0.2,4.2-0.5  c0.4,1.6,0.7,3.4,0.8,5.5h-5V10z M7.4,6.2c1.1-1,2.4-1.8,3.8-2.3c-0.7,0.9-1.2,2-1.7,3.2C8.7,6.9,8,6.7,7.3,6.4  C7.3,6.3,7.4,6.3,7.4,6.2z M5.8,7.9c0.9,0.4,1.9,0.8,3,1.1C8.4,10.9,8.1,12.9,8,15h-5C3.3,12.4,4.2,9.9,5.8,7.9z M5.8,24.1  c-1.6-2-2.6-4.5-2.8-7.1h5c0.1,2.1,0.4,4.1,0.9,5.9C7.8,23.3,6.7,23.6,5.8,24.1z M7.4,25.7c0,0-0.1-0.1-0.1-0.1  c0.7-0.3,1.4-0.6,2.2-0.8c0.5,1.2,1.1,2.3,1.7,3.2C9.8,27.5,8.5,26.7,7.4,25.7z M15,28.8c-1.3-0.5-2.6-2.1-3.5-4.4  c1.1-0.2,2.3-0.3,3.5-0.4V28.8z M15,22c-1.4,0-2.8,0.2-4.2,0.5C10.4,20.9,10.1,19,10,17h5V22z M15,15h-5c0.1-2,0.4-3.9,0.8-5.5  c1.3,0.3,2.7,0.4,4.2,0.5V15z M15,8c-1.2,0-2.4-0.2-3.5-0.4c0.9-2.3,2.2-3.9,3.5-4.4V8z"></path><polygon points="31,29.6 26.5,25.1 30.9,24 19,19 24,30.9 25.1,26.5 29.6,31 "></polygon></svg>

		                  </a>
		                </div>}
		                <div className="col-auto pl-0">
		                  <a
		                    href={`tel:${cleanPhoneNumber}`}
		                    className="phone-icon"
		                    title={`Call ${formattedPhoneNumber}`}
		                  >
		                    <span className="sr-only">{formattedPhoneNumber}</span>
		                    <svg
		                      xmlns="http://www.w3.org/2000/svg"
		                      fill="currentColor"
		                      width="30"
		                      height="30"
		                      viewBox="0 0 30 30"
		                    >
		                      <path d="M6.5 2.3c.1 0 .2 0 .3.1l4.6 4.7.1.1s.1.1-.1.3l-2.9 2.9-.5.5.3.7s1.3 3.5 4.1 6.2l.3.2c2.7 2.4 5.8 3.8 5.8 3.8l.7.3 3.4-3.4c.2-.2.2-.2.4 0l4.7 4.7c.2.2.2.1 0 .3l-3.5 3.5c-.5.5-1.1.5-1.8.3-2.6-1-7.7-3.3-12.2-7.8C5.6 15.1 3.3 10 2.4 7.5c-.2-.5 0-1.2.4-1.5l3.5-3.6c.1-.1.2-.1.2-.1zm0-2.3c-.6 0-1.2.2-1.6.6h-.1L1.1 4.4C0 5.4-.4 7 .1 8.3c1 2.8 3.5 8.2 8.3 13.1 4.9 4.9 10.4 7.3 13.1 8.4 1.4.5 2.9.1 4-.8l3.6-3.6c1-1 1-2.6 0-3.6l-4.7-4.7V17c-1-1-2.6-1-3.6 0l-2.3 2.3c-.8-.4-2.8-1.4-4.7-3.2-1.9-1.8-2.8-3.9-3.2-4.7l2.3-2.3c1-1 1-2.6 0-3.5l-.1-.1L8.3.7h-.1C7.7.2 7.1 0 6.5 0z" />
		                    </svg>
		                  </a>
		                </div>
		              </div>
		            </li>
				      )
	          })
		      : <li><h2>No dealer match for your specific search.</h2></li>
        }
      </ul>
    </div>
  )
}

export default PageContentFindDealer
