import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import formatProductTitle from "../helpers/formatProductTitle"
import QuoteForm from "../components/QuoteForm"

const ListAllProducts = ({ columns = 2, filter }) => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        products(
          first: 9999
          where: { orderby: { field: TITLE, order: DESC } }
        ) {
          nodes {
            databaseId
            title
            content
            slug
            featuredImage {
              node {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { products } = data.wp
  const [visibleProducts, setVisibleProducts] = useState([])

  useEffect(() => {
    const filteredProducts = []
    products.nodes &&
      products.nodes.map(product => {
        // const growingEnvironments = product.growingEnvironments.nodes.map(
        //   range => range.slug
        // )
        // const longevityRanges = product.longevityRanges.nodes.map(
        //   range => range.slug
        // )

        // if (
        //   !filter ||
        //   (growingEnvironments.some(env => filter.includes(env)) &&
        //     longevityRanges.some(range => filter.includes(range)))
        // ) {
        //   filteredProducts.push(product)
        // }
        filteredProducts.push(product)
      })

    setVisibleProducts(filteredProducts)
  }, [filter])

  return (
    <>
      <ul className="product-list row list-unstyled">
        {visibleProducts.map(product => {
          // const longevityRanges = product.longevityRanges.nodes.map(
          //   range => range.slug
          // )

          const formattedTitle = formatProductTitle(
            product.title,
            //longevityRanges.join(" ", ", ")
          )

          return (
            <li
              key={product.databaseId}
              className={`${columns === 1 ? "col-12 col-md-6" : "col-6"}`}
              style={{ position: "relative" }}
            >
              {product.featuredImage.node.imageFile && (
                <Img
                  fluid={{
                    ...product.featuredImage.node.imageFile.childImageSharp.fluid,
                    aspectRatio: 1
                  }}
                  objectFit="contain"
                  objectPosition="50% 50%"
                  loading="eager"
                />
              )}
              
              <h2 className="h4 text-center product-title">
                <Link
                  to={`/products/${product.slug}`}
                  className="stretched-link"
                  state={{ productsToShow: visibleProducts }}
                >
                  {formattedTitle}
                </Link>
              </h2>
            </li>
          )
        })}
      </ul>

      {visibleProducts?.length === 0 && filter?.[0] && filter?.[1] && (
        <h2>No product match for your specific search.</h2>
      )}

      {/* {visibleProducts.length > 0 && (
        <>
          <hr />
          <div class="bg-white border p-3 shadow-lg my-4">
          <h2 class="mb-3">Request a quote today!</h2>
          <QuoteForm productsToShow={visibleProducts} />
          </div>
        </>
      )} */}
    </>
  )
}

export default ListAllProducts
