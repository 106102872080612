import React, { useState, useEffect } from "react"
import SelectField from "./CustomSelectField"
import InputField from "./CustomInputField"
import { useStaticQuery, graphql } from "gatsby"

const PageContentFindProduct = props => {

  const [searchFilters, setSearchFilters] = useState({
    cropCategory: "",
    plantType: "",
    waterQuality: "",
    bicarbonates: "",
    alkalinity: "",
    errorCrop: "",
    errorWater: "",
  })

  // Update search filters as the user selects/types
  const handleFormFieldUpdate = e => {
    setSearchFilters({
      ...searchFilters,
      [e.target.name]: e.target.value,
    })
  }
  
  const [waterType, setWaterType] = useState(null) // Null here
  
  // Run this every time "searchFilters" change
  useEffect(() => {
    setWaterType(
	  (searchFilters.waterQuality == 'Very hard (Type 4)') ? 'Type 4' : 
	  (searchFilters.waterQuality == 'Soft (Type 2)') ? 'Type 2' : 
	  (searchFilters.waterQuality == 'Hard (Type 3)') ? 'Type 3' : 
	  (searchFilters.waterQuality == 'Very pure (Type 1)') ? 'Type 1' : 
	  (searchFilters.waterQuality == 'Not sure') ? 'Type 5' : 
	  (searchFilters.alkalinity === '') ? '' :
	  (searchFilters.alkalinity > 200) ? 'Type 4' : 
	  (searchFilters.alkalinity > 150 && searchFilters.alkalinity <= 200) ? 'Type 3' : 
	  (searchFilters.alkalinity > 60 && searchFilters.alkalinity <= 150) ? 'Type 2' :
	  (searchFilters.alkalinity >= 0 && searchFilters.alkalinity <= 60) ? 'Type 1' :
	  "Unclassified"
    )
  }, [searchFilters])
  
  const [cropGroup, setCropGroup] = useState(null) // Null here
  const groupA = ['African violet','Berries (liners)','Chrysanthemum','Cut flowers (general)','Cyclamen','Daylily','Ferns (general)','Flowering Pots (General)','Flowering hanging baskets (general)','Fruit trees (liners)','Grapes - liners','Hosta','New Guinea impatiens','Orchids (general)','Perennials (general)','Poinsettia','Trees and shrubs (flowering)','Containers/B&B','Verbena','Kalanchoe','Primula']
  const groupB = ['Ajuga','Bedding plants (general)','Begonias','Boxwood','Cucumber transplants','Dahlia, ground covers (general)','Ground covers (general)','Hydrangea','Impatiens','Lilies','Marigold','Native Perennials - Low Feeders','Pepper transplants','Plugs/liners (general)','Spring Bulb Crops','Tomato transplants','Trees and shrubs (conifers) - containers/B&B','Trees and shrubs (deciduous) - containers/B&B','Trees and shrubs (general) - containers/B&B']
  const groupC = ['Aster','Ornamental grasses','Roses - containerized']
  const groupD = ['Azalea','Florist azalea','Hollies - containerized','Trees and shrubs (ericaceous) - containers/B&B']
  const groupE = ['Palms','Pansy/viola','Petunia','Salvia','Vinca']
  const groupF = ['Gerbera','Vegetative spring crops (heavy feeders)']
  const groupG = ['Geranium']
  const groupH = ['Allamanda','Aglaonema','Anthurium','Dracaenas','Ficus','Foliage (General)','Foliage hanging baskets (general)','Mandevilla','Maranta','Spathiphyllum']
  const groupI = ['Hibiscus']
  const groupJ = ['Cucumbers (Hydroponics)','Hydroponics tanks (general)','Lettuce (hydroponics)','Pepper (hydroponics)','Spinach (hydroponics)','Tomato (hydroponics)']

  // Run this every time "searchFilters" change
  useEffect(() => {
    setCropGroup(
	  (groupA.includes(searchFilters.plantType) || groupA.includes(searchFilters.cropCategory)) ? 'Group A' : 
	  (groupB.includes(searchFilters.plantType) || groupB.includes(searchFilters.cropCategory)) ? 'Group B' : 
	  (groupC.includes(searchFilters.plantType) || groupC.includes(searchFilters.cropCategory)) ? 'Group C' : 
	  (groupD.includes(searchFilters.plantType) || groupD.includes(searchFilters.cropCategory)) ? 'Group D' : 
	  (groupE.includes(searchFilters.plantType) || groupE.includes(searchFilters.cropCategory)) ? 'Group E' : 
	  (groupF.includes(searchFilters.plantType) || groupF.includes(searchFilters.cropCategory)) ? 'Group F' : 
	  (groupG.includes(searchFilters.plantType) || groupG.includes(searchFilters.cropCategory)) ? 'Group G' : 
	  (groupH.includes(searchFilters.plantType) || groupH.includes(searchFilters.cropCategory)) ? 'Group H' : 
	  (groupI.includes(searchFilters.plantType) || groupI.includes(searchFilters.cropCategory)) ? 'Group I' : 
	  (groupJ.includes(searchFilters.plantType) || groupJ.includes(searchFilters.cropCategory)) ? 'Group J' : ''
    )
  }, [searchFilters])

  const handleFormSubmit = e => {
    e && e.preventDefault()
    
    var obj = searchFilters
    setSearchFilters({...searchFilters, obj})

  }

  const resetFormFields = () => setSearchFilters({
      cropCategory: "",
      plantType: "",
      waterQuality: "",
      bicarbonates: "",
      alkalinity: "",
      errorCrop: "",
      errorWater: "",
    })
  
  const data = useStaticQuery(graphql`
    query {
      wp {
        recommendations(
          first: 9999
          where: { orderby: { field: TITLE, order: ASC } }
        ) {
          nodes {
            databaseId
            title
            slug
            recommendationFields {
	          standAloneOption1
	          standAloneOption2
	          combinationRotationOption1
	          combinationRotationOption2
	        }
            cropGroups {
              nodes {
                databaseId
                name
                slug
              }
            }
            waterTypes {
              nodes {
                databaseId
                name
                slug
              }
            }
          }
        }
      }
    }
  `)
  
  const { recommendations } = data.wp
  const [visibleProducts, setVisibleProducts] = useState([])

  useEffect(() => {
    const filteredProducts = []
    recommendations.nodes &&
      recommendations.nodes.map(product => {
        const cropGroups = product.cropGroups.nodes.map(
          group => group.name
        )
        const waterTypes = product.waterTypes.nodes.map(
          type => type.name
        )

        if (
          (cropGroups.some(grp => cropGroup == grp) &&
            waterTypes.some(type => waterType == type))
        ) {
          filteredProducts.push(product)
        }
      })

    setVisibleProducts(filteredProducts)
  }, [waterType, cropGroup])

  return (
    <>
      <div id="search" className="anchor-offset"></div>
      <div className="container mb-3">
        <form onSubmit={handleFormSubmit}>
          {/* {searchFilters.cropCategory == '' && searchFilters.plantType == '' && ( */}
            <p className="text-secondary mb-3">STEP ONE - Please choose a <label for="cropCategory" className="mb-0"><strong>crop category</strong></label> -OR- <label for="plantType" className="mb-0"><strong>plant type</strong></label>.</p>
          {/* )} */}
          <div className="form-row">
            <div className="col-md-6">
              <SelectField
                name="cropCategory"
                label="Crop category"
                value={searchFilters.cropCategory}
                onSelectChange={handleFormFieldUpdate}
              >
                <option value="">Choose a crop category</option>
                <option>Bedding plants (general)</option>
                <option>Berries (liners)</option>
                <option>Containers/B&amp;B</option>
                <option>Cut flowers (general)</option>
                <option>Ground covers (general)</option>
                <option>Flowering hanging baskets (general)</option>
                <option>Fruit trees (liners)</option>
                <option>Foliage hanging baskets (general)</option>
                <option>Hydroponics tanks (general)</option>
                <option>Ornamental grasses</option>
                <option>Perennials (general) </option>
                <option>Plugs/liners (general)</option>
                <option>Trees and shrubs (conifers) - containers/B&amp;B</option>
                <option>Trees and shrubs (ericaceous) - containers/B&amp;B</option>
                <option>Trees and shrubs (flowering)</option>
                <option>Trees and shrubs (general) - containers/B&amp;B</option>
                <option>Vegetative spring crops (heavy feeders)</option>
              </SelectField>
            </div>
            <div className="col-md-6">
              <SelectField
                name="plantType"
                label="Plant type"
                onSelectChange={handleFormFieldUpdate}
                value={searchFilters.plantType}
              >
                <option value="">Choose a plant type</option>
                <option>African violet</option>
                <option>Aglaonema</option>
                <option>Ajuga</option>
                <option>Allamanda</option>
                <option>Anthurium</option>
                <option>Aster</option>
                <option>Azalea</option>
                <option>Begonias</option>
                <option>Boxwood</option>
                <option>Chrysanthemum</option>
                <option>Cucumber transplants</option>
                <option>Cyclamen</option>
                <option>Dahlia, ground covers (general)</option>
                <option>Daylily</option>
                <option>Dracaenas</option>
                <option>Ferns (general)</option>
                <option>Ficus</option>
                <option>Florist azalea</option>
                <option>Geranium</option>
                <option>Gerbera</option>
                <option>Grapes - liners</option>
                <option>Hibiscus</option>
                <option>Hollies - containerized</option>
                <option>Hosta</option>
                <option>Hydrangea</option>
                <option>Impatiens</option>
                <option>Kalanchoe</option>
                <option>Lettuce (hydroponics)</option>
                <option>Lilies</option>
                <option>Mandevilla</option>
                <option>Maranta</option>
                <option>Marigold</option>
                <option>New Guinea impatiens</option>
                <option>Orchids (general)</option>
                <option>Ornamental grasses</option>
                <option>Palms</option>
                <option>Pansy/viola</option>
                <option>Pepper (hydroponics)</option>
                <option>Pepper transplants</option>
                <option>Petunia</option>
                <option>Poinsettia</option>
                <option>Primula</option>
                <option>Roses - containerized</option>
                <option>Salvia</option>
                <option>Spathiphyllum</option>
                <option>Spinach (hydroponics)</option>
                <option>Tomato (hydroponics)</option>
                <option>Tomato transplants</option>
                <option>Verbena</option>
                <option>Vinca</option>
                <option>Not listed</option>
              </SelectField>
            </div>
          </div>
          {/* {searchFilters.waterQuality == '' && searchFilters.alkalinity == '' && ( */}
            <p className="text-secondary mb-3">STEP TWO - Please choose either a basic <label for="waterQuality" className="mb-0"><strong>water quality</strong></label> description, -OR- enter your known <label for="bicarbonates" className="mb-0"><strong>bicarbonate</strong></label> and <label for="alkalinity" className="mb-0"><strong>alkalinity</strong></label>. If you don’t know them, you can <a href="https://www.icl-labs.com/">order a water assessment here.</a></p>
          {/* )} */}
          <div className="form-row">
            <div className="col-lg-6">
              <SelectField
                name="waterQuality"
                label="Water quality"
                value={searchFilters.waterQuality}
                onSelectChange={handleFormFieldUpdate}
              >
                <option value="">Select water quality</option>
                <option>Very pure (Type 1)</option>
                <option>Soft (Type 2)</option>
                <option>Hard (Type 3)</option>
                <option>Very hard (Type 4)</option>
                <option>Not sure</option>
              </SelectField>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <InputField
                name="bicarbonates"
                type="number"
                label="Bicarbonates (mEq/L)"
                value={searchFilters.bicarbonates}
                onInputChange={handleFormFieldUpdate}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <InputField
                name="alkalinity"
                type="number"
                label="Alkalinity"
                value={searchFilters.alkalinity}
                onInputChange={handleFormFieldUpdate}
              />
            </div>
          </div>
          {/*<button type="submit" className="btn btn-primary btn-block">
            Search
          </button>*/}
          { (searchFilters.cropCategory || searchFilters.plantType) && (searchFilters.waterQuality || searchFilters.alkalinity) && (
            <p class="">
              <button className="btn btn-sm btn-secondary" onClick={resetFormFields}>Reset&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              </button>
            </p>
          ) }
        </form>
      </div>
      <div className="results">
        <div className="container">
            <hr />
              {searchFilters.plantType && searchFilters.plantType !== "Not listed" && (waterType && waterType !== "Type 5" && waterType !== "Unclassified") && (
                  <h2 className="my-5 text-icl font-weight-normal">Our recommendation based on the plant type: { searchFilters.plantType }</h2>
              )}
              {searchFilters.cropCategory && (searchFilters.plantType == "Not listed" || searchFilters.plantType == '') && (waterType && waterType !== "Type 5" && waterType !== "Unclassified") && (
                  <h2 className="my-5 text-icl font-weight-normal">Our recommendation based on the crop: { searchFilters.cropCategory }</h2>
              )}
              
              {waterType && waterType == "Unclassified" && (
                <p className="alert alert-primary">Sorry, we cannot give you a recommendation for your search criteria.</p>
              )}
              {waterType && waterType == "Type 5" && (searchFilters.plantType || searchFilters.cropCategory ) && (
                <div className="alert alert-icl">
                  <p className="mb-1"><strong>Sorry, we cannot recommend a product without knowing the water quality.</strong></p><p className="m-0">Irrigation water may contain disolved minerals and nurtients.  We design our fertilizer programs accounting for what is in the water.  A water test from the ICL lab reveals what is in the water.  Please go to <a href='https://www.icl-labs.com' target='_blank'>www.icl-labs.com</a> to order your $35.00 water test kit.</p>
                </div>
              )}
                	
                    
                    {cropGroup && waterType && waterType !== "Type 5" && waterType !== "Unclassified" && (
	                  <>
	                    <div className="row">
	                      {visibleProducts.map(item => {
	                        return(
	                          <div className="col-md-12">
	                            <h2>One Bag Program</h2>
	                            <h3 className="h5 text-body">Option I</h3>
	                            <p dangerouslySetInnerHTML={{ __html: item.recommendationFields.standAloneOption1.replace("https://petersapp.revhub.io/", "/") }}></p>
	                            <h3 className="h5 text-body">Option II</h3>
	                            <p dangerouslySetInnerHTML={{ __html: item.recommendationFields.standAloneOption2.replace("https://petersapp.revhub.io/", "/") }}></p>
	                          </div>
	                        )
	                      }) }
	                    </div>
	                    
	                    <div className="row">
	                      {visibleProducts.map(item => {
		                    return (
	                          <div className="col-md-12">
	                            <h2>Two-Product Combination or Rotation Program</h2>
	                            <h3 className="h5 text-body">Option I</h3>
	                            <p dangerouslySetInnerHTML={{ __html: item.recommendationFields.combinationRotationOption1.replace("https://petersapp.revhub.io/", "/") }}></p>
	                            <h3 className="h5 text-body">Option II</h3>
	                            <p dangerouslySetInnerHTML={{ __html: item.recommendationFields.combinationRotationOption2.replace("https://petersapp.revhub.io/", "/") }}></p>
	                          </div>
	                        )
	                      }) }
	                    </div>
	                  </>
                    )}
        </div>
      </div>
    </>
  )
}

export default PageContentFindProduct
