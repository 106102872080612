import React, { useState, useEffect } from "react"
import InputField from "./CustomInputField"

const PageContentAcidCalculator = () => {
  const [state, setState] = useState({
    bicarbonate: 0,
    alkalinity: 0,
    desiredAlkalinity: 0,
  })

  // Update values as the user selects/types
  const handleFormFieldUpdate = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }
  
  const [excess, setExcess] = useState(null) // Null here
  
  // Run this every time "state" changes
  useEffect(() => {
    setExcess(
	  state.alkalinity > (state.bicarbonate / 61) * 50
	    ? (state.alkalinity - state.desiredAlkalinity).toFixed(3)
	    : (state.bicarbonate / 61) * 50 > state.alkalinity
	    ? ((state.bicarbonate / 61) * 50 - state.desiredAlkalinity).toFixed(3)
	    : 0
    )
  }, [state])

  // const { page } = this.props
  return (
    <div className="container">
      <form>
        <div class="form-row">
          <div className="form-group col-md-6">
            <InputField
              name="bicarbonate"
              type="number"
              label="ppm bicarbonate from test results"
              onInputChange={handleFormFieldUpdate}
              labelAboveField={true}
            />
            <p className="small">
              ({((state.bicarbonate / 61) * 50).toFixed(3)} ppm total
              alkalinity)
            </p>
            <InputField
              name="alkalinity"
              type="number"
              label="ppm total alkalinity (CaCO3) from test result"
              onInputChange={handleFormFieldUpdate}
              labelAboveField={true}
            />
            <InputField
              name="desiredAlkalinity"
              type="number"
              label="desired ppm total alkalinity"
              onInputChange={handleFormFieldUpdate}
              labelAboveField={true}
            />
            <p className="small">({excess} ppm excess alkalinity to neutralize)</p>
          </div>
        </div>
      </form>
      <hr />
      {/*state.applicationEnv !== null && <h2>Results</h2>*/}

      <div className="table-responsive-wrapper">
        <h2 class="font-weight-normal text-icl">Amount of acid needed per 100 gallons</h2>
        <p>(fl. oz. or weight oz. for dry citric acid)</p>
        <div className="table-responsive table-sticky">
          <table className="table">
            {/* <thead>
                        <tr>
                            <th></th>
                            <th>Sulfuric Acid 93%</th>
                            <th>Sulfuric Acid 33% or Battery Acid</th>
                            <th>Phosphoric Acid 85%</th>
                            <th>Phosphoric Acid 75%</th>
                            <th>Nitric Acid 61%</th>
                            <th>Citric Acid 99.5%</th>
                        </tr>
                    </thead> */}
            <tbody>
              <tr>
                <th style={{ width: 150 }}>Sulfuric Acid 93%</th>
                <td className="text-center">
                  <p className="h3">{(excess / 100 * 0.744).toFixed(3)}</p>
                  <p>
                    ppm S added
                    <br />{(excess / 100 * 31.2).toFixed(3)}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Sulfuric Acid 33% or Battery Acid</th>
                <td className="text-center">
                  <p className="h3">{(excess / 100 * 2.096).toFixed(3)}</p>
                  <p>
                    ppm S added
                    <br />{(excess / 100 * 31.4).toFixed(3)}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Phosphoric Acid 85%</th>
                <td className="text-center">
                  <p className="h3">{(excess / 100 * 1.748).toFixed(3)}</p>
                  <p>
                    ppm P added
                    <br />{(excess / 100 * 62.1).toFixed(3)}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Phosphoric Acid 75%</th>
                <td className="text-center">
                  <p className="h3">{(excess / 100 * 2.12).toFixed(3)}</p>
                  <p>
                    ppm P added
                    <br />{(excess / 100 * 62.1).toFixed(3)}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Nitric Acid 61%</th>
                <td className="text-center">
                  <p className="h3">{(excess / 100 * 3.12).toFixed(3)}</p>
                  <p>
                    ppm N added
                    <br />{(excess / 100 * 43.7).toFixed(3)}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Citric Acid 99.5%</th>
                <td className="text-center">
                  <p className="h3">{(excess / 100 * 1.82).toFixed(3)}</p>
                  <p>
                    ***
                    <br />***
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="table-fade"></div>
        </div>
      </div>
      <div className="table-responsive-wrapper">
        <div className="table-responsive text-center">
          <table className="table">
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle" }}>
                  ppm CaCO3 to Neutralize
                </th>
                <th style={{ verticalAlign: "middle" }}>Sulfuric Acid 93%</th>
                <th style={{ verticalAlign: "middle" }}>
                  Sulfuric Acid 33% or Battery Acid
                </th>
                <th style={{ verticalAlign: "middle" }}>Phosphoric Acid 85%</th>
                <th style={{ verticalAlign: "middle" }}>Phosphoric Acid 75%</th>
                <th style={{ verticalAlign: "middle" }}>Nitric Acid 61%</th>
                <th style={{ verticalAlign: "middle" }}>Citric Acid 99.5%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>100</td>
                <td>0.744</td>
                <td>2.096</td>
                <td>1.748</td>
                <td>2.12</td>
                <td>3.12</td>
                <td>1.82</td>
              </tr>
              <tr>
                <td>ppm  N, S, or P</td>
                <td>{(42 * 0.744).toFixed(3)}</td>
                <td>{(15 * 2.096).toFixed(3)}</td>
                <td>{(35.5 * 1.748).toFixed(3)}</td>
                <td>{(29.3 * 2.12).toFixed(3)}</td>
                <td>{(14 * 3.12).toFixed(3)}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div className="table-fade"></div>
        </div>
      </div>
    
      {/*<hr />
      <div className="card card-body p-3 bg-light">
        <p className="font-weight-bold">Debug:</p>
        Bicarbonates: {state.bicarbonate}
        <br />
        Alkalinity: {state.alkalinity}
        <br />
        Desired Alkalinity: {state.desiredAlkalinity}
        <br />
        Excess: {excess}
        <br />
      </div>*/}
    </div>
  )
}

export default PageContentAcidCalculator
