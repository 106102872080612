import React from 'react'
import ListAllProducts from './ListAllProducts'

const PageContentProducts = (props) => {
    // const { page } = props
    return(
        <div className="container">
            <ListAllProducts />
        </div>
    )
}

export default PageContentProducts