import React, { Component } from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Layout from "../layouts/default"
import PageHeader from "../components/PageHeader"
import PageContentHome from "../components/PageContentHome"
import PageContentProducts from "../components/PageContentProducts"
import PageContentFindProduct from "../components/PageContentFindProduct"
import PageContentFindRates from "../components/PageContentFindRates"
import PageContentAcidCalculator from "../components/PageContentAcidCalculator"
import PageContentFindDealer from "../components/PageContentFindDealer"
import PageContentGetStarted from "../components/PageContentGetStarted"

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wp.page
    const { pageHeader, heroForeground, heroBackground, heroImage } = currentPage.pageHeader

    return (
      <Layout
        pageTitle={currentPage.title}
        slug={currentPage.slug}
        location={this.props.location}
        simpleHeader={heroImage !== null}
      >
        <SEO title={currentPage.title} />

        <PageHeader title={currentPage.title} img={pageHeader} foreground={heroForeground} background={heroBackground} heroImage={heroImage} />

        {/* Main Content */}
        {currentPage.content && (
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: currentPage.content }}
          />
        )}

        {/* Homepage Content */}
        {currentPage.slug === 'home' && (
          <PageContentHome page={currentPage} />
        )}
        
        {/* Products Page Content */}
        {currentPage.slug === 'products' && (
          <PageContentProducts page={currentPage} />
        )}

        {/* Find a Product Page Content */}
        {currentPage.slug === 'find-a-product' && (
          <PageContentFindProduct page={currentPage} />
        )}

        {/* Find Rates Page Content */}
        {currentPage.slug === 'find-rates' && (
          <PageContentFindRates page={currentPage} />
        )}

        {/* Find Rates Page Content */}
        {currentPage.slug === 'acid-calculator' && (
          <PageContentAcidCalculator page={currentPage} />
        )}

        {/* Find a Dealer Page Content */}
        {currentPage.slug === 'find-a-dealer' && (
          <PageContentFindDealer page={currentPage} />
        )}

        {/* Get Started Page Content */}
        {currentPage.slug === 'get-started' && (
          <PageContentGetStarted page={currentPage} />
        )}

      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: ID!) {
    wp {
      page(id: $id, idType: DATABASE_ID ) {
        title
        content
        slug
        pageId
        pageHeader {
          pageHeader {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 650) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          heroImage {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        panels {
          featuredPanels {
            icon
            title
            url
            image {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 800, maxHeight: 320) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
