import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import InputField from "./CustomInputField"
import SelectField from "./CustomSelectField"
import axios from "axios"

const PageContentGetStarted = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        gravityFormsForm(id: "R3Jhdml0eUZvcm1zRm9ybTox") {
          fields {
            nodes {
              ... on WP_SelectField {
                id
                isRequired
                label
                type
                choices {
                  isSelected
                  text
                  value
                }
              }
              ... on WP_TextField {
                id
                isRequired
                label
                type
              }
              ... on WP_PhoneField {
                id
                isRequired
                inputName
                label
                type
              }
              ... on WP_EmailField {
                id
                isRequired
                label
                type
              }
            }
          }
        }
      }
    }
  `)

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isOffline, setIsOffline] = useState(false)

  const handleOnline = () => setIsOffline(false)
  const handleOffline = () => setIsOffline(true)

  useEffect(() => {
    if (navigator.onLine) {
      setIsOffline(false)
    } else {
      setIsOffline(true)
    }

    window.addEventListener('online', handleOnline );
    window.addEventListener('offline', handleOffline );

    console.log("Offline:", isOffline)

    return () => {
      window.removeEventListener('online', handleOnline );
      window.removeEventListener('offline', handleOffline );
    }
  })

  const handleSubmit = async e => {
    e.preventDefault()

    const anchor = document.querySelector('.page-title');
    anchor.scrollIntoView()

    const formData = new FormData(e.target)

    // Convert FormData into JSON
    var dataObj = {
      form_id: "1",
      baseUrl: "https://petersapp.revhub.io/wp-json/gf/v2",
    }

    formData.forEach(function (value, key) {
      dataObj[key] = value
    })
    var formGrowerVal = dataObj['input_6'] || '';
    var jsonData = JSON.stringify(dataObj)

    try {
      setIsError(false)
      setIsLoading(true)
      const result = await axios({
        method: "POST",
        url: "/.netlify/functions/newGfEntry",
        data: jsonData,
      })
        .then(function (response) {
          setIsLoading(false)
          setIsSuccess(true)
          window.dataLayer = window.dataLayer || []
		  window.dataLayer.push({
		    event: 'tba.form.confirmation',
		    eventData: {
			  formTitle: 'Ask a Specialist',
			  formLocation: '/get-started/',	
			  formGrower: formGrowerVal
		    }
		  })
        })
        .catch(function (error) {
          setIsLoading(false)
          setIsSuccess(false)
          setIsError(true)
        })
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
    }
  }

  return (
    <div className="container">
      {isOffline && (
        <div className="alert alert-warning form-error">
          <strong>Your device is offline</strong> <br/>Please connect to a network to submit this form.
        </div>
      )}
      {isError && (
        <div className="alert alert-danger form-error">
          Sorry, an unexpected error occurred. Please try again.
        </div>
      )}
      {isSuccess ? (
        <div className="alert alert-success form-success">
          Thanks for contacting us! We will be in touch soon.
        </div>
      ) : (
        <form
          onSubmit={e => handleSubmit(e)}
          className={`${isLoading && "form-loading"}`}
        >
          {data.wp.gravityFormsForm.fields.nodes.map(field =>
            field.type === "select" ? (
              <SelectField
                name={`input_${field.id}`}
                label={field.label}
                key={field.type + field.id}
              >
                {field.choices.map((option, i) => (
                  <option
                    value={option.value}
                    key={`${field.type}-${field.id}-${i}`}
                  >
                    {option.text}
                  </option>
                ))}
              </SelectField>
            ) : (
              <InputField
                name={`input_${field.id}`}
                type={field.type}
                label={field.label}
                required={field.isRequired}
                key={field.type + field.id}
              />
            )
          )}
          <button className="btn btn-primary btn-block" type="submit">
            Submit
          </button>

          {isLoading && (
            <div className="loading-rings">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </form>
      )}
    </div>
  )
}

export default PageContentGetStarted
