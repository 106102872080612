import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const PageContentHome = props => {
  const { page } = props
  const { featuredPanels } = page.panels
  return (
    <div className="container">
      {featuredPanels && (
        <ul className="home-panels list-unstyled row justify-content-stretch">
          {featuredPanels.map(panel => {
            return (
              <li key={panel.title} className="col-12 col-lg-6">
                <figure className="home-panel">
                  <Img fluid={panel.image.imageFile.childImageSharp.fluid} loading="eager" />
                  <figcaption>
                    <h2>
                      <Link to={panel.url} className="home-panel-link">
                        {panel.icon && (
                          <i className={panel.icon} aria-hidden="true"></i>
                        )}
                        {panel.title}
                      </Link>
                    </h2>
                  </figcaption>
                </figure>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.99 217.84" className="shape-overlay" fill="#fff"><path d="M27.42.09a28.77 28.77 0 0 1 7.45 1.13A24.78 24.78 0 0 1 49.86 13c43.69 75.8 66.95 115.25 110.71 191a32.35 32.35 0 0 1 2.57 5 5.7 5.7 0 0 1-3.35 7.67 16.94 16.94 0 0 1-5.47 1.08l12.1.13V0" transform="translate(-27.42)" /></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.5 155.83" className="shape-accent" fill="#00b9d2">
                  <path d="M296.74,155.37a17.27,17.27,0,0,1-12.43-8.72C252.77,92,235.85,63.29,204.26,8.65c-.8-1.37-1.58-2.75-1.35-4.43C203.26,1.72,205.62.07,209,0c4.79,0,14.2-.08,19,0a20.4,20.4,0,0,1,5.33.8A17.72,17.72,0,0,1,244,9.33c31.25,54.19,47.9,82.41,79.2,136.57a22.79,22.79,0,0,1,1.83,3.58,4.08,4.08,0,0,1-2.39,5.49,12.41,12.41,0,0,1-3.92.77c-2.45.11-18.75.22-22-.37" transform="translate(-202.87)" />
                </svg>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default PageContentHome
